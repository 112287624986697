@font-face {
  font-family: "NotoSansKR";
  src: url("../assets/fonts/NotoSansKR.ttf") format("truetype");
}

@font-face {
  font-family: "NanumGothic";
  font-weight: 300;
  src: url("../assets/fonts/nanumgothic/NanumGothicLight.ttf")
    format("truetype");
}

@font-face {
  font-family: "NanumGothic";
  font-weight: 400;
  src: url("../assets/fonts/nanumgothic/NanumGothic.ttf") format("truetype");
}

@font-face {
  font-family: "NanumGothic";
  font-weight: 700;
  src: url("../assets/fonts/nanumgothic/NanumGothicBold.ttf") format("truetype");
}

@font-face {
  font-family: "NanumGothic";
  font-weight: 800;
  src: url("../assets/fonts/nanumgothic/NanumGothicExtraBold.ttf")
    format("truetype");
}

@font-face {
  font-family: "applegothic";
  src: url("../assets/fonts/apple-gothic.ttf") format("truetype");
}

.pc {
  display: block;
}

.mobile {
  display: none;
}

* {
  font-family: "NotoSansKR";
  color: #fff;
}

img {
  max-width: 100%;
}

#wrap {
  /* background-color: #000; */
  overflow: hidden;
  background-image: url("../assets/images/background_v3.png");
  background-size: 100% auto;
  background-position: center top;
  background-repeat: repeat-y;
}

/* .slick-track {
  display: flex;
}

.slick-dots {
  bottom: -30px;
} */

header {
  position: absolute;
  z-index: 9;
  left: 0;
  top: 0;
  width: 100%;
}

header .mobile-menu {
  display: none;
}

header .hd-wrap {
  display: flex;
  height: 110px;
  align-items: center;
  justify-content: space-between;
  flex-flow: row nowrap;
}

header .left-area {
  display: flex;
  align-items: center;
  gap: 0 30px;
}

header #main-menu {
  display: flex;
}

header #main-menu ul {
  gap: 0 25px;
}

header #main-menu ul li a {
  font-size: 1.5rem;
  font-weight: 700;
  color: #fff;
  text-shadow: 2px 2px 13px rgba(0, 0, 0, 0.53);
}

header .right-area {
  align-self: flex-start;
  display: flex;
  align-items: center;
  gap: 0 15px;
  padding-top: 30px;
}

header #util-menu > ul {
  gap: 0 10px;
  align-items: center;
}

header #util-menu > ul > li > a {
  display: inline-block;
  width: 32px;
  height: 32px;
  /* border-radius: 50%;
  border: 1px solid #fff;
  background-color: #000; */
}

header #util-menu .buy-now {
  margin-left: 15px;
}

header #util-menu .buy-now button {
  background-color: #fff;
  border: 0;
  color: #000;
  font-size: 1.4rem;
  font-weight: 700;
  padding: 4px 14px;
  min-width: 120px;
  min-height: 40px;
}

header .language-select .now-select-parent {
  display: flex;
  align-items: center;
  gap: 0 6px;
}

header .language-select .now-select {
  font-size: 1.3rem;
}

footer {
  padding-top: 120px;
  padding-bottom: 160px;
  position: relative;
  z-index: 10;
  /* background-color: #000; */
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0.6) 30%,
    rgba(0, 0, 0, 1) 100%
  );
}

footer .footer-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

footer .ft-top {
  display: flex;
  justify-content: space-between;
}

footer .ft-top > div {
  flex: 1 1 33.33%;
}

footer .ft-top .ft-logo {
  margin-top: -25px;
  text-align: center;
}

footer .ft-top .sitemap-area > ul {
  gap: 10px 0;
}

footer .ft-top .sitemap-area > ul > li {
  flex: 1 1 50%;
}

footer .ft-top .sitemap-area > ul > li > a {
  color: #fff;
  font-size: 1.5rem;
  font-weight: 500;
}

footer .ft-top .company-info p {
  font-size: 1.3rem;
  line-height: 180%;
}

footer .sponsored-text {
  text-align: center;
  margin-top: 20px;
}

footer .sponsored-text p {
  font-size: 1.5rem;
}

footer .copyright {
  margin-top: 35px;
  text-align: center;
}

footer .copyright p {
  font-size: 1.3rem;
  color: #999999;
}

@media (max-width: 1400px) {
  .pc {
    display: none;
  }

  .mobile {
    display: block;
  }

  html {
    font-size: 8px;
  }

  header .mobile-menu {
    display: flex;
    flex-direction: column;
    gap: 8px 0;
    width: 36px;
  }
  header .mobile-menu > span {
    background-color: #afb0c4;
    width: 100%;
    height: 6px;
  }
  header .hd-wrap {
    height: auto;
    padding: 18px 10px;
  }
  header #logo img {
    width: 120px;
  }
  header #main-menu {
    display: none;
  }

  header .language-select {
    display: none;
  }

  header .right-area {
    align-self: center;
    padding-top: 0;
    gap: 0 12px;
  }

  header #util-menu > ul {
    gap: 0 8px;
    flex-flow: row nowrap;
  }

  header #util-menu > ul > li > a {
    width: auto;
    height: auto;
  }

  header #util-menu > ul > li img {
    width: 20px;
  }

  header #util-menu .buy-now {
    margin-left: 0;
  }

  header #util-menu .buy-now button {
    font-size: 10;
    padding: 4px 8px;
  }

  footer {
    padding-bottom: 40px;
    /* margin-top: 40px; */
  }

  footer .ft-top .sitemap-area > ul > li {
    text-align: center;
    /* flex: 0 1 50%; */
  }

  footer .ft-top .ft-logo {
    margin-top: 0;
  }

  footer .ft-top {
    flex-direction: column;
    align-items: center;
  }

  footer .ft-top .company-info p {
    text-align: center;
  }
}
