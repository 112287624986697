.section {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 150px 0;
}

.section .notice-container {
  width: 900px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.section .notice-container .title {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 20px;
}

.section .notice-container .notice-wrapper {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  background-color: rgb(0, 0, 0, 0.7);
  border-radius: 10px;
}

.notice-item {
  display: flex;
  flex-direction: column;
}

.section .notice-container .notice-wrapper .notice {
  position: relative;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
}

.notice .notice-left {
  width: 70%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 30px;
}

.notice .notice-left .notice-title {
  font-size: 1.6rem;
  font-weight: 500;
  color: white;
  white-space: nowrap; /* 줄바꿈 방지 */
  overflow: hidden; /* 넘치는 텍스트 숨기기 */
  text-overflow: ellipsis; /* 말줄임표 적용 */
}

.notice .notice-right {
  width: 20%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notice .notice-right .notice-date {
  font-size: 1.6rem;
  font-weight: 500;
  color: white;
}

.notice .notice-spread {
  position: absolute;
  top: 50%;
  right: 20px;
  height: auto;
  transform: translateY(-50%);
  background: none;
}

.notice .notice-spread img {
  width: 20px;
  height: auto;
}

.notice-item .notice-content {
  width: 100%;
  height: fit-content;
  padding: 20px 30px;
  background-color: rgb(0, 0, 0, 0.8);
}

.notice-content p {
  font-size: 1.4rem;
  font-weight: 400;
  margin: 0;
}

@media screen and (max-width: 1400px) {
  .section {
    padding: 110px 0;
  }

  .section .notice-container {
    width: 90%;
  }

  .notice .notice-left {
    width: 60%;
  }
}
