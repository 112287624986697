@font-face {
  font-family: "NotoSansKR";
  src: url("../assets/fonts/NotoSansKR.ttf") format("truetype");
}

@font-face {
  font-family: "NanumGothic";
  font-weight: 300;
  src: url("../assets/fonts/nanumgothic/NanumGothicLight.ttf")
    format("truetype");
}

@font-face {
  font-family: "NanumGothic";
  font-weight: 400;
  src: url("../assets/fonts/nanumgothic/NanumGothic.ttf") format("truetype");
}

@font-face {
  font-family: "NanumGothic";
  font-weight: 700;
  src: url("../assets/fonts/nanumgothic/NanumGothicBold.ttf") format("truetype");
}

@font-face {
  font-family: "NanumGothic";
  font-weight: 800;
  src: url("../assets/fonts/nanumgothic/NanumGothicExtraBold.ttf")
    format("truetype");
}

@font-face {
  font-family: "applegothic";
  src: url("../assets/fonts/apple-gothic.ttf") format("truetype");
}

.pc {
  display: block;
}

.mobile {
  display: none;
}

* {
  font-family: "NotoSansKR";
  color: #fff;
}

img {
  max-width: 100%;
}

#wrap {
  /* background-color: #000; */
  overflow: hidden;
  background-image: url("../assets/images/background_v3.png");
  background-size: 100% auto;
  background-position: center top;
  background-repeat: repeat-y;
}

#wrap .rocket-overlay {
  position: absolute;
  display: flex;
  width: 100%; /* 부모 요소의 너비를 기준으로 설정 */
  /* height: 0; */
  padding-top: calc(723 / 1923 * 100%);
  background-size: cover;
  background-image: url("../assets/images/rocket_v2.png");
  pointer-events: none;
}

/* .slick-track {
  display: flex;
}

.slick-dots {
  bottom: -30px;
} */

header {
  position: absolute;
  z-index: 9;
  left: 0;
  top: 0;
  width: 100%;
}

header .mobile-menu {
  display: none;
}

header .hd-wrap {
  display: flex;
  height: 110px;
  align-items: center;
  justify-content: space-between;
  flex-flow: row nowrap;
}

header .left-area {
  display: flex;
  align-items: center;
  gap: 0 30px;
}

header #main-menu {
  display: flex;
}

header #main-menu ul {
  gap: 0 35px;
  flex-flow: row;
}

header #main-menu ul li a {
  font-size: 1.5rem;
  font-weight: 700;
  color: #fff;
  text-shadow: 2px 2px 13px rgba(0, 0, 0, 0.53);
}

header .right-area {
  align-self: flex-start;
  display: flex;
  align-items: center;
  gap: 0 20px;
  /* padding-top: 25px; */
}

header #util-menu > ul {
  gap: 0 10px;
  align-items: center;
  flex-flow: row;
}

header #util-menu > ul > li > a {
  display: inline-block;
  width: 32px;
  height: 32px;
  /* border-radius: 50%;
  border: 1px solid #fff;
  background-color: #000; */
}

header #util-menu .buy-now {
  margin-left: 20px;
}

header #util-menu .buy-now button {
  background-color: #fff;
  border: 0;
  color: #000;
  font-size: 1.4rem;
  font-weight: 700;
  padding: 4px 14px;
  min-width: 120px;
  min-height: 40px;
}

header .language-select .now-select-parent {
  display: flex;
  align-items: center;
  gap: 0 6px;
}

header .language-select .now-select {
  font-size: 1.3rem;
}

header .language {
  position: relative;
  letter-spacing: 0.02em;
  text-decoration: none;
  text-transform: capitalize;
  font-weight: 400;
  padding: 0 1rem;
}
header .language .language-btn {
  display: flex;
  align-items: center;
}
header .language .language-btn:after {
  content: "";
  width: 14px;
  height: 20px;
  display: inline-block;
  background: url("../assets/images/flags/angle-down.svg") no-repeat center /
    12px;
  transition: transform 0.25s ease-in-out;
}
header .language.active .language-btn:after {
  transform: rotate(180deg);
}
header .language .language-btn .language-icon {
  border-radius: 50%;
  overflow: hidden;
  margin-left: 3px;
  width: 15px;
  height: 15px;
  object-fit: cover;
}
header .language .language-btn .language-text {
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
  margin: 0 0.5rem;
}
header .language .language-list {
  position: absolute;
  width: 220px;
  max-height: 270px;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #000;
  border-radius: 3px;
  top: 100%;
  right: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.25s ease-in-out;
}
header .language.active .language-list {
  top: calc(100% + 10px);
  opacity: 1;
  visibility: inherit;
}
header .language .language-list::-webkit-scrollbar {
  width: 10px;
}
header .language .language-list::-webkit-scrollbar-thumb {
  background: #aa46b3;
}
header .language .language-list::-webkit-scrollbar-track {
  background: #000;
}
header .language .language-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  padding: 0.75rem 1rem;
  width: 100%;
  background-color: transparent;
}
header .language .language-item img {
  border-radius: 50%;
  border: 1px solid #ffffff;
  overflow: hidden;
  margin-right: 10px;
  min-width: 20px;
  height: 20px;
  width: 20px;
  object-fit: cover;
}
header .language .language-item span {
  color: #fff;
}

footer {
  padding-top: 120px;
  padding-bottom: 160px;
  position: relative;
  z-index: 10;
  /* background-color: #000; */
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0.6) 30%,
    rgba(0, 0, 0, 1) 100%
  );
}

footer .footer-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

footer .ft-top {
  display: flex;
  justify-content: space-between;
}

footer .ft-top > div {
  flex: 1 1 33.33%;
}

footer .ft-top .ft-logo {
  margin-top: -25px;
  text-align: center;
}

footer .ft-top .sitemap-area > ul {
  gap: 10px 0;
}

footer .ft-top .sitemap-area > ul > li {
  flex: 1 1 50%;
}

footer .ft-top .sitemap-area > ul > li > a {
  color: #fff;
  font-size: 1.5rem;
  font-weight: 500;
}

footer .ft-top .company-info p {
  font-size: 1.3rem;
  line-height: 180%;
}

footer .sponsored-text {
  text-align: center;
  margin-top: 20px;
}

footer .sponsored-text p {
  font-size: 1.5rem;
}

footer .copyright {
  margin-top: 35px;
  text-align: center;
}

footer .copyright p {
  font-size: 1.3rem;
  color: #999999;
}

@media (max-width: 1400px) {
  .pc {
    display: none;
  }

  .mobile {
    display: block;
  }

  html {
    font-size: 8px;
  }

  #wrap {
    background-image: url("../assets/images/background-mobile_v3.png") !important;
  }

  #wrap .rocket-overlay {
    position: absolute;
    display: flex;
    right: 0;
    width: 100%; /* 부모 요소의 너비를 기준으로 설정 */
    padding-top: calc(1682 / 1920 * 100%);
    background-size: cover;
    background-image: url("../assets/images/rocekt-mobile_v2.png");
  }

  header {
    position: fixed;
    transition: background-color 0.5s ease-in-out;
  }
  header.top {
    background-color: rgba(0, 0, 0, 0.9);
  }

  header .mobile-menu {
    display: block;
  }
  header .mobile-menu .mobile-menu-btn {
    display: flex;
    flex-direction: column;
    gap: 5px 0;
    width: 20px;
    height: 16px;
    background: transparent;
  }
  header .mobile-menu .mobile-menu-btn > span {
    background-color: #fafafa;
    width: 100%;
    height: 2px;
  }
  header .mobile-menu.active .mobile-menu-btn {
    gap: 0;
  }
  header .mobile-menu.active .mobile-menu-btn > span:nth-child(1) {
    transform: rotate(45deg) translate(4px, 6px);
    width: 24px;
  }
  header .mobile-menu.active .mobile-menu-btn > span:nth-child(2) {
    display: none;
  }
  header .mobile-menu.active .mobile-menu-btn > span:nth-child(3) {
    transform: rotate(-45deg) translate(-10px, 9px);
    margin-top: -8px;
    width: 24px;
  }
  header .mobile-menu .mobile-menu-wrap {
    position: absolute;
    top: 69px;
    right: -100%;
    width: 100%;
    height: calc(100vh - 62px);
    background-color: rgba(0, 0, 0, 0.9);
    padding: 20px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease-in-out;
  }
  header .mobile-menu.active .mobile-menu-wrap {
    opacity: 1;
    visibility: inherit;
    right: 0;
  }
  header .mobile-menu .mobile-menu-wrap .mobile-menu-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  header .mobile-menu .mobile-menu-wrap .mobile-menu-list li {
    margin-bottom: 20px;
  }
  header .mobile-menu .mobile-menu-wrap .mobile-menu-list li + li {
  }
  header .mobile-menu .mobile-menu-wrap .mobile-menu-list li a {
    padding: 5px;
    color: #fff;
    letter-spacing: 0.02em;
    font-weight: 500;
    font-size: 18px;
  }
  header .mobile-menu .mobile-menu-wrap .mobile-util {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem 0 4rem 0;
  }
  header .mobile-menu .mobile-menu-wrap .mobile-util li {
  }
  header .mobile-menu .mobile-menu-wrap .mobile-util li a {
    padding: 5px;
  }
  header .mobile-menu .mobile-menu-wrap .mobile-language {
    position: relative;
  }
  header .mobile-menu .mobile-menu-wrap .mobile-language .language-btn {
    padding: 10px 36px 10px 14px;
    border: 1px solid #1ddaff;
    letter-spacing: 0.02em;
    margin: 4rem 0;
    border-radius: 22px;
    display: flex;
    align-items: center;
  }
  header .mobile-menu .mobile-menu-wrap .mobile-language .language-btn:after {
    content: "";
    width: 20px;
    height: 24px;
    display: inline-block;
    background: url("../assets/images/flags/angle-down.svg") no-repeat center /
      20px;
    transition: transform 0.25s ease-in-out;
    position: absolute;
    right: 16px;
    top: 10px;
    transform: rotate(180deg);
  }
  header
    .mobile-menu
    .mobile-menu-wrap
    .mobile-language.active
    .language-btn:after {
    transform: rotate(0deg);
  }
  header
    .mobile-menu
    .mobile-menu-wrap
    .mobile-language
    .language-btn
    .language-icon {
    border-radius: 50%;
    overflow: hidden;
    margin-left: 3px;
    min-width: 15px;
    width: 15px;
    height: 15px;
    object-fit: cover;
  }
  header
    .mobile-menu
    .mobile-menu-wrap
    .mobile-language
    .language-btn
    .language-text {
    font-size: 16px;
    margin-left: 0.5rem;
    height: 24px;
    line-height: 22px;
    text-transform: uppercase;
  }
  header .mobile-menu .mobile-menu-wrap .mobile-language .language-list {
    position: absolute;
    width: 100%;
    max-height: 373px;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #000;
    border-radius: 3px;
    bottom: 100%;
    right: 0;
    opacity: 0;
    visibility: hidden;
    transition: all 0.25s ease-in-out;
  }
  header .mobile-menu .mobile-menu-wrap .mobile-language.active .language-list {
    bottom: calc(100% + 10px);
    opacity: 1;
    visibility: inherit;
  }
  header
    .mobile-menu
    .mobile-menu-wrap
    .mobile-language
    .language-list::-webkit-scrollbar {
    width: 10px;
  }
  header
    .mobile-menu
    .mobile-menu-wrap
    .mobile-language
    .language-list::-webkit-scrollbar-thumb {
    background: #aa46b3;
  }
  header
    .mobile-menu
    .mobile-menu-wrap
    .mobile-language
    .language-list::-webkit-scrollbar-track {
    background: #000;
  }
  header .mobile-menu .mobile-menu-wrap .mobile-language .language-item {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    padding: 0.75rem 1rem;
    width: 100%;
    background-color: transparent;
  }
  header .mobile-menu .mobile-menu-wrap .mobile-language .language-item img {
    border-radius: 50%;
    border: 1px solid #ffffff;
    overflow: hidden;
    margin-right: 10px;
    min-width: 20px;
    height: 20px;
    width: 20px;
    object-fit: cover;
  }
  header .mobile-menu .mobile-menu-wrap .mobile-language .language-item span {
    color: #fff;
  }
  header .mobile-menu .mobile-menu-wrap .mobile-buy-now {
    font-size: 14px;
    font-weight: 600;
    border-radius: 35px;
    border: none;
    padding: 12px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 120px;
    min-height: 45px;
    background: linear-gradient(92deg, #1ddaff 1.73%, #ea1af7 99.52%);
    color: #000;
    font-weight: 700;
    width: 100%;
  }
  header .hd-wrap {
    height: auto;
    padding: 18px 10px;
  }
  header #logo img {
    width: 120px;
  }
  header #main-menu {
    display: none;
  }

  header .language-select {
    display: none;
  }

  header .right-area {
    align-self: center;
    padding-top: 0;
    gap: 0 12px;
  }

  header #util-menu {
    display: none;
  }

  header #util-menu > ul {
    gap: 0 8px;
    flex-flow: row nowrap;
  }

  header #util-menu > ul > li > a {
    width: auto;
    height: auto;
  }

  header #util-menu > ul > li img {
    width: 20px;
  }

  header #util-menu .buy-now {
    margin-left: 0;
  }

  header #util-menu .buy-now button {
    font-size: 10;
    padding: 4px 8px;
  }

  header .language {
    display: none;
  }

  footer {
    padding-bottom: 40px;
    /* margin-top: 40px; */
  }

  footer .ft-top .sitemap-area > ul > li {
    text-align: center;
    /* flex: 0 1 50%; */
  }

  footer .ft-top .ft-logo {
    margin-top: 0;
  }

  footer .ft-top {
    flex-direction: column;
    align-items: center;
  }

  footer .ft-top .company-info p {
    text-align: center;
  }
}
