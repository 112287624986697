#main-banner {
  /* background-image: url("../assets/images/main/banner-back.png"); */
  padding-bottom: 70px;
}

#main-banner .banner-wrap {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: flex-end;
  /* position: relative; */
}
/* 
{
  box-sizing: border-box;
} */

#main-banner .banner-wrap .left-area .banner-char {
  /* position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -200px; */
}

#main-banner .banner-wrap .left-area .char-text {
  text-align: center;
  margin-top: -30px;
}

#main-banner .banner-wrap .left-area .char-text p {
  font-size: 5.2rem;
  font-weight: 800;
  text-shadow: 0 0 15px rgba(255, 255, 255, 0.6);
  line-height: 110%;
  font-family: "NanumGothic";
  font-style: italic;
}

#main-banner .banner-wrap .left-area {
  flex: 0 1 auto;
  margin-left: -200px;
}

#main-banner .banner-wrap .right-area {
  flex: 1 1;
  /* flex: 1 1 800px; */
}

#main-banner .banner-wrap .top-logo {
  margin-bottom: 10px;
}

#main-banner .banner-wrap .banner-text {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}

#main-banner .banner-wrap .banner-text h4 {
  font-family: "applegothic";
  font-size: 4.6rem;
  font-weight: 800;
  text-shadow: 0px 0px 10px rgb(106 0 52);
}

#main-banner .banner-wrap .banner-text p {
  margin-top: 10px;
  font-size: 3.2rem;
  font-weight: 400;
  text-shadow: 0px 0px 10px rgb(106 0 52);
}

#main-banner .banner-wrap .project-info {
  display: flex;
  margin-bottom: 30px;
  padding: 0 20px;
}

#main-banner .banner-wrap .project-info > .info-item {
  flex: 1 1;
}

#main-banner .banner-wrap .project-info > .info-item:last-child {
  flex: 0 1 auto;
}

#main-banner .banner-wrap .info-item .name {
  font-weight: 200;
  font-size: 2rem;
  text-shadow: 0px 0px 10px rgb(106 0 52);
}

#main-banner .banner-wrap .info-item p {
  font-weight: 900;
  font-size: 2rem;
  text-shadow: 0px 0px 10px rgb(106 0 52);
}

#main-banner .banner-wrap .info-item {
  flex: 1 1;
}

#main-banner .banner-wrap .banner-btn-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 30px;
  margin-top: 30px;
}

#main-banner .banner-wrap .banner-btn-container .banner-btn {
  display: flex;
  gap: 0 45px;
  width: 100%;
}

#main-banner .banner-wrap .banner-btn-container .banner-btn button,
#main-banner .banner-wrap .banner-btn-container .banner-btn a {
  flex: 1 1;
  height: 85px;
  color: #fff;
  font-size: 2.5rem;
  font-weight: 700;
  border-radius: 25px;
}

#main-banner .banner-wrap .banner-btn-container .banner-btn .paper-btn {
  background: linear-gradient(to right, #00daff, #fc08b3);
  display: flex;
  justify-content: center;
  align-items: center;
}

#main-banner .banner-wrap .banner-btn-container .banner-btn .audit-btn {
  background-color: rgba(0, 0, 0, 0.2);
  border: 3px solid #9900ff;
}

#main-banner .banner-wrap .banner-btn-container .help-center-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  background: linear-gradient(to right, #9900ff, #ff00ff);
  color: #fff;
  font-size: 2.5rem;
  font-weight: 700;
  border-radius: 25px;
  height: 85px;
}

#section > div {
  padding: 28px 0;
}

#section .sec-title {
  text-align: center;
  margin-bottom: 40px;
}

#section .sec-title h2 {
  font-weight: 700;
  font-size: 7.3rem;
}

#section .sec-title p {
  font-size: 4rem;
}

#section1 {
  background-image: url("../assets/images/main/sec1_back.png");
  background-size: auto 100%;
}

#section1 .sec-wrap {
  display: flex;
  padding-left: 60px;
}

#section1 .left-area {
  flex: 0 1 510px;
}

#section1 .right-area {
  flex: 1 1;
}

#section1 .your-elonxcat {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px 0;
  width: 100%;
  background: url("../assets/images/main/sec2_form2.png") no-repeat center top /
    100% 100%;
  padding: 50px 0;
  height: 820px;
}

#section1 .your-elonxcat .title {
  text-align: center;
  padding: 0 10px;
}

#section1 .your-elonxcat .title h4 {
  font-weight: 900;
  font-size: 1.9rem;
  word-break: keep-all;
}

#section1 .your-elonxcat .title h4 span {
  font-weight: 400;
}

#section1 .your-elonxcat .count-down {
  display: flex;
  gap: 0 15px;
  align-items: center;
}

#section1 .your-elonxcat .count-down .time-item {
  background-image: url("../assets/images/icons/count_item.png");
}

#section1 .your-elonxcat .count-down .time-item span {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 3.4rem;
  font-weight: 700;
  width: 66px;
  height: 75px;
}

#section1 .your-elonxcat .count-down .clones-item span {
  font-size: 3.4rem;
  font-weight: 700;
}

#section1 .your-elonxcat .my-credit {
  border: 1px solid #00497a;
  border-radius: 20px;
  padding: 15px 24px;
  text-align: center;
  width: calc(100% - 90px);
}

#section1 .your-elonxcat .my-credit ul {
  flex-direction: column;
}

#section1 .your-elonxcat .my-credit ul li {
  font-size: 2rem;
  color: #fff;
  line-height: 110%;
}

#section1 .your-elonxcat .my-credit ul li b {
  font-weight: 700;
}

#section1 .your-elonxcat .balance-text p {
  font-size: 1.6rem;
  font-weight: 700;
}

#section1 .your-elonxcat .token-date {
  margin-top: -6px;
}

#section1 .your-elonxcat .token-date p {
  font-weight: 500;
  font-size: 1.4rem;
  color: #00b1ef;
}

#section1 .your-elonxcat .bottom-btn {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  padding: 0 70px;
  gap: 4px 0;
  margin: 0 -2px;
}

#section1 .your-elonxcat .bottom-btn > div:nth-child(1) {
  flex: 1 1 100%;
}

#section1 .your-elonxcat .bottom-btn > div {
  flex: 1 1 50%;
  padding: 0 2px;
}

#section1 .your-elonxcat .bottom-btn .bottom-text {
  font-size: 16px;

  text-align: center;

  width: 100%;

  font-weight: 600;

  margin-top: 4px;
}

#section1 .your-elonxcat .inp-list {
  padding: 0 70px;
  list-style: none;
  width: 100%;
}
#section1 .your-elonxcat .inp-list .inp-item {
  width: 100%;
}
#section1 .your-elonxcat .inp-list .inp-item + .inp-item {
  margin-top: 20px;
}
#section1 .your-elonxcat .inp-list .inp-item .inp-box {
  width: 100%;
  background-color: #021733;
  border: 1px solid #00b1ef;
  border-radius: 12px;
  padding: 0 58px 0 26px;
  margin-top: 4px;
  position: relative;
  overflow: hidden;
}
#section1 .your-elonxcat .inp-list .inp-item .inp-box:before,
#section1 .your-elonxcat .inp-list .inp-item .inp-box:after {
  content: "";
  position: absolute;
}
#section1 .your-elonxcat .inp-list .inp-item .inp-box:before {
  top: 0;
  width: 48px;
  bottom: 0;
  background-color: #093a75;
  right: 0;
}
#section1 .your-elonxcat .inp-list .inp-item .inp-box:after {
  top: 8px;
  right: 8px;
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
}
#section1
  .your-elonxcat
  .inp-list
  .inp-item:nth-child(1)
  .inp-box.active1:after {
  background-image: url("../assets/images/main/sec2_icon1.png");
}
#section1
  .your-elonxcat
  .inp-list
  .inp-item:nth-child(1)
  .inp-box.active2:after {
  background-image: url("../assets/images/main/sec2_icon2_2.png");
}
#section1
  .your-elonxcat
  .inp-list
  .inp-item:nth-child(1)
  .inp-box.active3:after {
  background-image: url("../assets/images/main/eth-icon.png");
  background-size: contain;
}
#section1 .your-elonxcat .inp-list .inp-item:nth-child(2) .inp-box {
  display: none;
}
#section1 .your-elonxcat .inp-list .inp-item:nth-child(2) .inp-box.active {
  display: block;
}
#section1
  .your-elonxcat
  .inp-list
  .inp-item:nth-child(2)
  .inp-box:nth-child(2):after {
  background-image: url("../assets/images/main/sec2_icon2.png");
}

#section1 .your-elonxcat .inp-list .inp-item label {
  display: block;
  font-size: 13px;
  font-weight: 600;
  color: #fff;
}
#section1 .your-elonxcat .inp-list .inp-item input {
  font-size: 16px;
  height: 45px;
  background-color: transparent;
  border: 0;
}
#section1 .your-elonxcat .inp-list .inp-item:nth-child(1) input {
}
#section1 .your-elonxcat .inp-list .inp-item:nth-child(2) input {
}
#section1 .your-elonxcat .inp-list .inp-item:nth-child(2) input.active {
}
#section1 .your-elonxcat .inp-list .inp-item:nth-child(2) input:nth-child(1) {
}
#section1 .your-elonxcat .inp-list .inp-item:nth-child(2) input:nth-child(2) {
}
#section1 .your-elonxcat .inp-list .wallet-btn {
}

#section1 .your-elonxcat .bottom-btn button {
  font-size: 1.6rem;
  font-weight: 700;
  background-color: #00b1ef;
  width: 100%;
  height: 45px;
  border: 1px solid #005e7f;
  border-radius: 16px;
}

#section1 .your-elonxcat .bottom-btn > div:nth-child(1) button {
  flex: 1 1 100%;
  background: linear-gradient(to right, #00daff, #fc08b3);
}

#section1 .your-elonxcat .wallet-btn {
  width: 100%;
  padding: 0 70px;
}
#section1 .your-elonxcat .wallet-btn button {
  font-size: 1.6rem;
  font-weight: 700;
  width: 100%;
  height: 45px;
  border: 0;
  border-radius: 16px;
  background: linear-gradient(to bottom, #fa0fb5 40%, #96096d 80%);
}

#section2 {
  /* background-image: url("../assets/images/main/sec2_back.png"); */
}

#section2 .sec-list {
  display: flex;
  margin: 0 -20px;
}

#section2 .sec-list .sec-item {
  flex: 1 1;
  padding: 0 20px;
  text-align: center;
}

#section2 .sec-list .sec-item .box {
  background-image: url("../assets/images/main/sec2_item_back_hover.png");
  min-height: 340px;
  padding: 30px 10px 0;
}

#section2 .sec-list .sec-item .box:hover {
  background-image: url("../assets/images/main/sec2_item_back.png");
}

#section2 .sec-item .icon {
  margin-bottom: 15px;
}

#section2 .sec-list .sec-item:nth-child(1) .title h3 {
  color: #f00;
}

#section2 .sec-list .sec-item:nth-child(2) .title h3 {
  color: #75ea0e;
}

#section2 .sec-list .sec-item:nth-child(3) .title h3 {
  color: #05aafe;
}

#section2 .sec-list .sec-item:nth-child(4) .title h3 {
  color: #ff0eeb;
}

#section2 .sec-item .title {
  margin-bottom: 20px;
}

#section2 .sec-item .title h3 {
  font-size: 3.7rem;
  font-weight: 900;
}

#section2 .sec-item .price p {
  font-size: 2.4rem;
  font-weight: 900;
}

#section2 .sec-item .text p {
  font-size: 1.4rem;
}

.sec3-back-image {
  position: absolute;
  width: 100vw;
  margin-top: -300px;
}

.sec3-back-image img {
  width: 100vw;
}

#section3 .sec-list {
  display: flex;
  flex-flow: row wrap;
  margin: 0 -20px;
  padding-top: 45px;
}

#section3 .sec-list .sec-item {
  flex: 1 1;
  padding: 0 20px;
  text-align: center;
}

#section3 .sec-list .sec-item .box {
  height: 360px;
  background-color: #1e2835;
  border-radius: 20px;
  position: relative;
  overflow: visible;
  padding-top: 60px;
  padding: 60px 14px 0;
  border: 1px solid #0ac6e8;
}

#section3 .sec-item .icon {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
}

#section3 .sec-item .title h4 {
  font-size: 1.9rem;
  font-weight: 900;
}

#section3 .sec-item .text p {
  font-size: 1.2rem;
}

#section3 .sec-item .bottom-function {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 30px);
}

#section3 .sec-item .status-bar {
  display: flex;
  align-items: center;
  height: 33px;
  margin-bottom: 14px;
}

#section3 .sec-item .status-bar .status-text {
  flex: 1 1;
  background-color: #171f27;
  height: 100%;
  border-radius: 8px;
  line-height: 33px;
}

#section3 .sec-item .status-bar .status-text p {
  font-size: 1.2rem;
}

#section3 .sec-item .status-bar .title h4 {
  font-size: 1.2rem;
  color: #999999;
  font-weight: 500;
}

#section3 .sec-item .status-bar .status-value {
  background-color: #f8d10d;
  width: 45px;
  height: 100%;
  border-radius: 8px;
  line-height: 33px;
}

#section3 .sec-item .status-bar .status-value span {
  color: #000;
  font-size: 1.4rem;
  font-weight: 500;
}

#section3 .bottom-btn {
  margin-top: 14px;
}

#section3 .bottom-btn button {
  background-color: #89f300;
  color: #000;
  font-weight: 500;
  height: 32px;
  width: 80px;
  border-radius: 8px;
  font-size: 1.4rem;
}

#section4 .play-elonxcat {
  display: flex;
  min-height: 420px;
  position: relative;
}

#section4 .play-elonxcat .parent-wrap {
  position: relative;
}

#section4 .play-elonxcat .left-back {
  width: 730px;
  background-image: url("../assets/images/main/sec4_left_back.png");
  height: 100%;
  border-radius: 20px;
  border: 1px solid #993366;
}

#section4 .play-elonxcat .game-info {
  width: 500px;
  height: 100%;
  background-color: #150e1b;
  padding: 40px 25px;
  border: 1px solid #3558b5;
  border-radius: 20px;
  position: absolute;
  top: 0;
  right: -430px;
}

#section4 .play-elonxcat .game-info .title h4 {
  font-size: 3.4rem;
  color: #fff;
  font-weight: 900;
}

#section4 .play-elonxcat .game-info .text {
  margin-top: 14px;
}

#section4 .play-elonxcat .game-info .text p {
  font-size: 1.6rem;
  line-height: 130%;
}

#section4 .play-elonxcat .game-info .bottom-btn {
  margin-top: 16px;
  position: absolute;
  width: calc(100% - 50px);
  left: 50%;
  transform: translateX(-50%);
  bottom: 23px;
}

#section4 .play-elonxcat .game-info .bottom-btn button {
  background-color: #ff6600;
  border-radius: 25px;
  width: 100%;
  height: 50px;
  font-size: 1.7rem;
  font-weight: 700;
  line-height: 18px;
  padding: 0 5px;
}

#section5 {
  position: relative;
  /* background-image: url("../assets/images/main/sec5_back.png"); */
}

#section5 .left-back-image {
  position: absolute;
  left: 0;
  top: -300px;
}

#section5 > .container {
  position: relative;
  z-index: 1;
}

#section5 .sec-wrap {
  display: flex;
  position: relative;
}

#section5 .left-area {
  width: 550px;
  max-width: 100%;
  position: relative;
  z-index: 1;
}

#section5 .left-area .info-row {
  display: flex;
  flex-direction: column;
  gap: 24px 0;
}

#section5 .left-area .info-item {
  display: flex;
  align-items: center;
  gap: 0 35px;
}

#section5 .left-area .info-item .icon {
  flex: 0 1 auto;
}

#section5 .left-area .info-item .icon img {
  width: 110px;
}

#section5 .left-area .info-item .right-area {
  flex: 1 1;
}

#section5 .left-area .info-item .title {
  margin-bottom: 14px;
}

#section5 .left-area .info-item .title h4 {
  font-size: 2.5rem;
  font-weight: 700;
  text-shadow: 1px 1px 20px #000;
}

#section5 .left-area .info-item .text p {
  font-size: 1.8rem;
  text-shadow: 1px 1px 20px #000;
}

#section5 .sec-wrap > .right-area {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#section6 {
  /* background-image: url("../assets/images/main/sec6_back.png"); */
  background-position: 0px center;
  position: relative;
}

#section6 .sec-list {
  display: flex;
  margin: 0 -25px;
  margin-bottom: 35px;
}

#section6 .sec-list .sec-item {
  flex: 1 1;
  padding: 0 25px;
  text-align: center;
}

#section6 .sec-item .box {
  background-color: rgba(88, 36, 107, 0.5);
  padding: 15px 15px 50px;
  overflow: visible;
  border-radius: 10px;
}

#section6 .sec-item .top-title {
  background-color: #58246b;
  border-radius: 15px;
  padding: 12px 0;
  margin-bottom: 28px;
}

#section6 .sec-item .top-title h4 {
  font-size: 2.6rem;
  font-weight: 900;
  line-height: 90%;
}

#section6 .sec-item .top-title h4 small {
  font-size: 1.6rem;
}

#section6 .sec-item .icon {
  width: 100%;
  border: 3px solid #fff;
  border-radius: 50%;
  margin-bottom: 30px;
}

#section6 .sec-item .icon img {
  width: 100%;
}

#section6 .sec-item .top-text {
  margin-bottom: 12px;
}

#section6 .sec-item .top-text p {
  font-size: 1.5rem;
  color: #fff;
}

#section6 .sec-item .middle-text {
  margin-bottom: 20px;
}

#section6 .sec-item .middle-text p {
  font-size: 1.5rem;
}
#section6 .sec-item .middle-text p strong {
  font-size: 1.8rem;
  color: #00b1ef;
}

#section6 .sec-item .bottom-text p {
  font-size: 1.5rem;
  color: #fff;
  font-weight: 700;
}

#section6 .sec-item .opacity-box {
  background-color: rgba(88, 36, 107, 0.8);
  border-radius: 10px;
  padding: 14px 0;
}

#section6 .sec-item .opacity-box > ul {
  flex-direction: column;
  gap: 4px 0;
}

#section6 .sec-item .opacity-box {
  margin-bottom: 20px;
}

#section6 .sec-item .opacity-box > ul > li {
  font-size: 1.5rem;
  line-height: 110%;
}

#section6 .sec-item .discover-btn {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  /* transform: translateY(50%); */
}

#section6 .sec-item .discover-btn button {
  background-color: #ffccff;
  color: #663366;
  width: calc(100% - 40px);
  height: 70px;
  font-size: 1.5rem;
  font-weight: 700;
  border-radius: 15px;
}

.sec7-back-image {
  width: 100vw;
  position: absolute;
  transform: translateY(-300px);
}

.sec7-back-image img {
  width: 100vw;
}

#section7 {
  position: relative;
  z-index: 1;
}

#section7 .sec-wrap {
  display: flex;
}

#section7 .sec-wrap > .left-area {
  flex: 0 1 auto;
}

#section7 .sec-wrap > .right-area {
  flex: 1 1;
}

#section7 .sec-list {
  display: flex;
  flex-direction: column;
  gap: 40px 0;
}

#section7 .sec-item .list-wrap {
  display: flex;
  align-items: center;
  gap: 0 25px;
}

#section7 .sec-item .list-wrap .icon {
  flex: 0 1 auto;
}

#section7 .sec-item .list-wrap .text-area {
  flex: 1 1;
  max-width: 340px;
}

#section7 .sec-item .list-wrap .text-area h4 {
  margin-bottom: 20px;
  font-weight: 700;
}

#section7 .sec-item .list-wrap .text-area p {
  font-size: 1.3rem;
}

#section8 {
  position: relative;
  z-index: 1;
}

#section8 .sec-title h2 {
  line-height: 90%;
}

#section8 .sec-title .left {
  text-align: left;
  display: block;
  font-weight: 700;
}

#section8 .sec-title .right {
  width: 100%;
  text-align: right;
  display: block;
  font-weight: 700;
}

#section8 .sec-wrap {
  display: flex;
}

#section8 .sec-wrap > .left-area {
  flex: 0 1 auto;
}

#section8 .sec-wrap > .right-area {
  flex: 1 1;
}

#section8 .right-area .text-area p {
  font-size: 2rem;
}

#section8 .logo-list {
  display: flex;
  flex-flow: row wrap;
  gap: 8px 0;
  margin-top: 45px;
}

#section8 .logo-list .logo-item {
  flex: 1 1 33.33%;
  text-align: center;
}

#section9 {
  /* background-image: url("../assets/images/main/sec9_back.png"); */
}

#section9 .sec-wrap {
  display: flex;
  gap: 0 40px;
}

#section9 .sec-wrap > .left-area {
  flex: 0 1 auto;
}

#section9 .left-area .chart-area {
  width: 630px;
  height: 630px;
  background: transparent url("../assets/images/main/sec9_left.png") center
    center no-repeat;
  background-size: 100%;
  /* background-color: #001725; */
  /* border: 1px solid #98fc01; */
  /* border-radius: 50%; */
}

#section9 .left-area .chart-area canvas {
  width: 100%;
  height: 100%;
}

#section9 .left-area .chart-area .token_posleft {
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  overflow: hidden;
}

#section9 .sec-wrap > .right-area {
  flex: 1 1;
}

#section9 .right-area .area-parent {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 30px;
  border: 1px solid #fff;
  padding: 30px 20px 20px;
}

#section9 .right-area .top-text h4 {
  font-size: 3rem;
  color: #fff;
  font-weight: 900;
}

#section9 .right-area .top-text p {
  font-size: 2.3rem;
  font-weight: 900;
}

#section9 .right-area .top-text p:nth-child(2) span {
  color: #00ffff;
  font-weight: 900;
}

#section9 .right-area .top-text p:nth-child(3) span {
  color: #99ff00;
  font-weight: 900;
}

#section9 .right-area table {
  width: 100%;
  margin-top: 30px;
  border-collapse: separate;
  border-spacing: 0 2px;
}

#section9 .right-area table tbody tr {
  margin-bottom: 10px;
}

#section9 .right-area table tr th {
  font-size: 1.8rem;
  padding: 8px 14px;
}

#section9 .right-area table tr th:last-child {
  text-align: right;
}

#section9 .right-area table tr td {
  font-size: 1.8rem;
  background-color: #000;
  padding: 12px 10px;
  border-top: 1px solid #003366;
  border-bottom: 1px solid #003366;
}

#section9 .right-area table tr td:nth-child(1) {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-left: 1px solid #003366;
  padding-left: 35px;
  position: relative;
}

#section9 .right-area table tr td:nth-child(1):before {
  content: "";
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 10px;
  position: absolute;
  top: 19px;
  left: 15px;
  background: red;
}
#section9 .right-area table tr:nth-child(1) td:nth-child(1):before {
  background: #85d5ef;
}
#section9 .right-area table tr:nth-child(2) td:nth-child(1):before {
  background: #38bfef;
}
#section9 .right-area table tr:nth-child(3) td:nth-child(1):before {
  background: #5c84c3;
}
#section9 .right-area table tr:nth-child(4) td:nth-child(1):before {
  background: #5361ac;
}
#section9 .right-area table tr:nth-child(5) td:nth-child(1):before {
  background: #8066ad;
}
#section9 .right-area table tr:nth-child(6) td:nth-child(1):before {
  background: #cea8ce;
}
#section9 .right-area table tr:nth-child(7) td:nth-child(1):before {
  background: #d558a1;
}

#section9 .right-area table tr td:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-right: 1px solid #003366;
}

#section9 .right-area table tr td:nth-child(2) {
  text-align: right;
}

#section9 .right-area table tr td:nth-child(3) {
  text-align: center;
}

.sec10-back-image {
  position: absolute;
  width: 100vw;
  transform: translateY(-200px);
}

.sec10-back-image img {
  width: 100vw;
}

#section1,
#section2,
#section3,
#section4,
#section4,
#section5,
#section6,
#section7,
#section8,
#section9,
#section10,
#section11,
#section12,
#section13,
#section14,
#section15,
#section16,
#section17,
#section18 {
  position: relative;
  z-index: 1;
}

#section10 {
}
#section10 .container {
  max-width: 1077px;
}

#section10 .sub-info {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

#section10 .sub-info > .left-area {
  flex: 1 1;
}

#section10 .sub-info > .right-area {
  flex: 0 1 auto;
}

#section10 .sub-info > .left-area p {
  font-size: 1.6rem;
}

#section10 .sub-info > .right-area .token-btn button {
  background-image: url("../assets/images/main/btn-back.png");
  background-position: 0 center;
  height: 65px;
  font-size: 2rem;
  font-weight: 700;
  text-shadow: 2px 2px 0 rgb(0, 0, 0);
  border-radius: 12px;
  padding: 0 22px;
}

#section10 .token-status-row {
  display: flex;
  gap: 0 2px;
}

#section10 .token-status-row > .status-item {
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 15px;
  border: 1px solid #605466;
  padding: 30px 25px;
  min-height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#section10 .token-status-row > .status-item:nth-child(1) {
  flex: 1 1 200px;
}

#section10 .token-status-row > .status-item:nth-child(2) {
  flex: 1 1 180px;
}

#section10 .token-status-row > .status-item:nth-child(3) {
  flex: 1 1 250px;
}

#section10 .token-status-row > .status-item:nth-child(4) {
  flex: 1 1 220px;
}

#section10 .token-status-row > .status-item:nth-child(5) {
  flex: 1 1 190px;
}

#section10 .token-status-row p {
  font-size: 1.6rem;
}

#section10 .token-status-row p b {
  font-weight: 700;
}

#section10 .token-status-row p {
  font-weight: 300;
}

#section10 .token-status-row .reward-btn {
  background-image: url("../assets/images/main/btn-back.png");
  width: 100%;
  border-radius: 10px;
  font-size: 1.5rem;
  font-weight: 700;
  height: 35px;
  line-height: 19px;
}

#section10 .total-supply {
  margin-top: 30px;
  position: relative;
}

#section10 .total-supply .left-area {
  width: 895px;
  position: relative;
  z-index: 1;
}

#section10 .total-supply .title {
  text-align: center;
}

#section10 .total-supply .total-supply-parent {
  width: 100%;
  height: 455px;
  border: 1px solid #593268;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 40px 0;
  padding-right: 40px;
  max-width: 100%;
  border-radius: 15px;
}

#section10 .total-supply .title h4 {
  font-size: 2rem;
  font-weight: 700;
}

#section10 .total-supply .staking-powered-by {
  text-align: center;
  margin-top: 4px;
}

#section10 .total-supply .staking-powered-by p {
  font-size: 1.5rem;
  font-weight: 200;
}

#section10 .total-supply .right-area {
  position: absolute;
  right: -400px;
  top: 50%;
  transform: translateY(-50%);
}

.brand-parent {
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 20px;
  padding: 35px 35px;
}

.brand-parent .brand-list {
  gap: 20px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
}

.brand-parent .brand-list .brand-item {
  width: calc(25% - 20px);
  height: 80px;
  text-align: center;
  background: rgba(255, 255, 255, 0.05);
  display: flex;
  justify-content: space-between;
  align-items: normal;
  flex-flow: row wrap;
  border-radius: 10px;
}
.brand-parent .brand-list .brand-item img {
  max-width: 80%;
  max-height: 80%;
  margin: auto;
}

/* #section11,
#section12,
#section13,
#section14,
#section15,
#section16,
#section17,
#section18 {
  padding: 40px 0 !important;
} */

#section11 {
  padding-top: 0 !important;
}

#section12 {
  /* padding-bottom: 0 !important; */
}

#section11 .brand-parent {
}

#section13 .sec-wrap {
  display: flex;
  gap: 0 30px;
}

#section13 .sec-wrap > .left-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#section13 .sec-wrap > .right-area {
  flex: 1 1;
}

#section13 .sec-wrap > .right-area .text-parent {
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 20px;
  padding: 25px 50px;
}

#section13 .sec-wrap > .right-area .text-parent p {
  font-size: 1.7rem;
}

#section14 .sec-wrap {
  display: flex;
  flex-flow: row wrap;
  gap: 60px 85px;
  justify-content: center;
}

#section14 .sec-wrap .sec-item {
  flex: 0 1 auto;
  position: relative;
}

#section14 .sec-item .card-text {
  position: absolute;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}

#section14 .sec-item .card-text p {
  text-align: center;
  width: 100%;
  line-height: 120%;
  font-size: 1.6rem;
  text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.75);
}

#section14 .sec-item .card-text p b {
  font-size: 2.2rem;
  font-weight: 900;
  text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.75);
}

#section15 {
  position: relative;
}

#section15 .sec-title {
  margin-bottom: 120px;
}

#section15 .left-image {
  position: absolute;
  left: 0;
  top: 0;
}

#section15 .right-image {
  position: absolute;
  right: 0;
  bottom: -250px;
}

#section15 .step-row {
  display: flex;
  flex-direction: column;
  gap: 80px 0;
}

#section15 .step-row .step-item:nth-child(1) .step-number {
  background-image: url("../assets/images/main/sec15_step1.png");
  left: 50px;
  padding-right: 20px;
}

#section15 .step-row .step-item:nth-child(2) .step-number {
  background-image: url("../assets/images/main/sec15_step2.png");
  right: 50px;
  padding-left: 20px;
}

#section15 .step-row .step-item:nth-child(3) .step-number {
  background-image: url("../assets/images/main/sec15_step3.png");
  left: 50px;
  padding-right: 20px;
}

#section15 .step-item {
  width: 80%;
  position: relative;
  border: 1px solid #3e5e8f;
  border-radius: 45px;
  padding: 40px 60px;
}

#section15 .step-item:nth-child(1) {
  box-shadow: 0 0 50px #fa1dc3;
  background-color: #6a1560;
}

#section15 .step-item:nth-child(2) {
  box-shadow: 0 0 50px #3333cc;
  align-self: flex-end;
  background-color: #333366;
}

#section15 .step-item:nth-child(3) {
  box-shadow: 0 0 50px #669900;
  background-color: #669900;
}

#section15 .step-item .step-number {
  position: absolute;
  top: -50px;
  width: 305px;
  height: 85px;
  text-align: center;
}

#section15 .step-item .step-number h4 {
  font-size: 4.6rem;
  font-weight: 700;
  line-height: 75px;
}

#section15 .step-item .text h5 {
  font-size: 3.1rem;
  font-weight: 700;
  margin-bottom: 4px;
}

#section15 .step-item .text p {
  font-size: 2.3rem;
  font-weight: 500;
}

.sec16-back {
  position: absolute;
  width: 100%;
  transform: translateY(-720px);
  padding-bottom: 0 !important;
}

.sec16-back img {
  width: 100vw;
}

#section16 {
  padding-top: 100px !important;
}

#section16 .sec-title {
  margin-bottom: 75px;
}

#section16 .sec-wrap:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  background-color: #2f85cf;
  width: 100vw;
  height: 10px;
}

#section16 .sec-wrap {
  display: flex;
  gap: 0 30px;
  position: relative;
  padding-top: 65px;
}

#section16 .sec-wrap .sec-item {
  flex: 1 1;
}

#section16 .sec-wrap .sec-item .box {
  height: 530px;
  padding: 50px 30px;
  position: relative;
  overflow: visible;
  background-size: 100% 100%;
}

#section16 .sec-item .box:before {
  position: absolute;
  width: 50px;
  height: 55px;
  left: 50%;
  top: -60px;
  transform: translate(-50%, -50%);
}

#section16 .sec-wrap .sec-item .box:hover:before {
  content: "";
  background-image: url("../assets/images/icons/green_back_right.png");
  background-repeat: no-repeat;
}
#section16 .sec-item-1 .box {
  background-image: url("../assets/images/main/sec16_1.png");
}

#section16 .sec-item-1 .box:before {
  content: "";
  background-image: url("../assets/images/icons/blue_back_right.png");
  background-repeat: no-repeat;
}

#section16 .sec-item-2 .box {
  background-image: url("../assets/images/main/sec16_2.png");
}

#section16 .sec-item-2 .box:before {
  content: "";
  background-image: url("../assets/images/icons/blue_back_right.png");
  background-repeat: no-repeat;
}

#section16 .sec-item-3 .box {
  background-image: url("../assets/images/main/sec16_3.png");
}

#section16 .sec-item-3 .box:before {
  content: "";
  background-image: url("../assets/images/icons/blue_back_right.png");
  background-repeat: no-repeat;
}

#section16 .sec-item-4 .box {
  background-image: url("../assets/images/main/sec16_4.png");
}

#section16 .sec-item-4 .box:before {
  content: "";
  background-image: url("../assets/images/icons/blue_back_right.png");
  background-repeat: no-repeat;
}

#section16 .sec-item-5 .box {
  background-image: url("../assets/images/main/sec16_5.png");
}

#section16 .sec-item-5 .box:before {
  content: "";
  background-image: url("../assets/images/icons/blue_back_right.png");
  background-repeat: no-repeat;
}

#section16 .sec-item .title {
  margin-bottom: 30px;
}

#section16 .sec-item .title h4 {
  font-size: 3.4rem;
  font-weight: 900;
  color: #66ccff;
  margin-bottom: 20px;
}

#section16 .sec-item .title p {
  font-size: 2rem;
  font-weight: 900;
  color: #66ccff;
}

#section16 .sec-item .text p {
  font-size: 2rem;
}

#section17 .sec-title h2 {
  letter-spacing: 2px;
}

#section17 .question-wrap {
  display: flex;
  flex-direction: column;
  padding: 20px 60px 80px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 25px;
}

#section17 .question-wrap > .question-item {
  border-bottom: 1px solid #4e4c52;
  padding: 22px 10px;
  display: flex;
  align-items: center;
  gap: 0 30px;
  display: none;
  background: rgba(255, 255, 255, 0.05);
}
#section17 .question-wrap > .question-item.aq_header {
  display: flex;
  cursor: pointer;
  background: transparent;
}
#section17 .question-wrap > .question-item.aq_header.on + .question-item {
  display: block;
}
#section17 .question-item .text {
  flex: 1 1;
}

#section17 .question-item .arrow {
  flex: 0 1 auto;
}

#section17 .question-item .text p {
  font-size: 2rem;
}

#section18 {
  padding-top: 20px !important;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.1) 30%,
    rgba(0, 0, 0, 0.3) 100%
  );
}

#section18 .social-list {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  gap: 0 45px;
}

#section18 .social-list > .social-item {
  border: 10px solid #fff;
  border-radius: 10px;
  width: 200px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

#section18 .social-list > .social-item .text {
  margin-top: 10px;
}

#section18 .social-list > .social-item .text p {
  font-size: 2rem;
  font-weight: 500;
}

#section18 .social-list > .social-item:nth-child(1) {
  box-shadow: 0 0 10px #750aa2;
}

@media (max-width: 1400px) {
  * {
    background-size: cover;
    object-fit: cover;
  }
  .brand-parent {
    padding: 12px 20px;
  }

  #main-banner {
    padding-top: 40px;
  }

  #main-banner .banner-wrap {
    flex-flow: column wrap;
    position: relative;
  }

  #main-banner .banner-wrap > div {
    width: 100%;
  }

  #main-banner .banner-wrap .left-area .char-text {
    display: none;
  }

  #main-banner .banner-wrap .top-logo {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-bottom: 0;
  }

  #main-banner .banner-wrap .top-logo img {
    width: 85%;
  }

  #main-banner .banner-wrap .banner-text h4 {
    font-size: 22px;
    text-align: center;
  }

  #main-banner .banner-wrap .banner-text p {
    font-size: 16px;
    text-align: center;
    margin-top: 15px;
  }

  #main-banner .banner-wrap .right-area {
    margin-top: -160px;
  }

  #main-banner .banner-wrap .project-info > .info-item:last-child {
    display: none;
  }

  #main-banner .banner-wrap .project-info > .info-item {
    text-align: center;
  }

  #main-banner .banner-wrap .info-item .name {
    font-size: 12px;
  }

  #main-banner .banner-wrap .info-item p {
    font-size: 12px;
  }

  #main-banner .banner-wrap .banner-btn-container .banner-btn {
    gap: 0 20px;
    padding: 0 30px;
  }

  #main-banner .banner-wrap .banner-btn-container .banner-btn button,
  #main-banner .banner-wrap .banner-btn-container .banner-btn a {
    font-size: 14px;
    height: 50px;
    border-radius: 16px;
  }

  #main-banner .banner-wrap .banner-btn-container .help-center-btn {
    font-size: 14px;
    height: 50px;
    border-radius: 16px;
  }

  #section .sec-list {
    flex-direction: column;
    align-items: center;
    gap: 15px 0;
    padding-top: 0;
  }

  #section .sec-title {
    margin-bottom: 20px;
  }

  #section .sec-title h2 {
    font-size: 32px;
  }

  #section .sec-title p {
    font-size: 16px;
  }

  #section > div {
    padding: 30px 0;
  }

  #section1 .sec-wrap {
    padding-left: 0;
    justify-content: center;
  }

  #section1 .left-area {
    flex: 0 1 auto;
  }

  #section1 .right-area {
    display: none;
  }

  #section1 .your-elonxcat {
    gap: 5px 0;
    padding: 35px 0;
    width: 390px;
    max-width: 100%;
    background-size: 100% 720px;
    height: 720px;
  }

  #section1 .your-elonxcat .count-down .time-item span {
    width: 40px;
    height: 48px;
  }

  #section1 .your-elonxcat .my-credit {
    padding: 8px 24px;
  }

  #section1 .your-elonxcat .bottom-btn button {
    height: 36px;
  }

  #section2 .sec-list {
    /* display: block; */
    flex-flow: row wrap;
  }

  #section2 .sec-list .sec-item {
    display: flex;
    justify-content: center;
    text-align: center;
  }

  #section2 .sec-list .sec-item .box {
    width: 260px;
    height: 340px;
    min-height: unset;
  }

  #section2 .sec-list .sec-item .icon {
    text-align: center;
    display: flex;
    justify-content: center;
  }

  #section3 .sec-list {
    flex-direction: row;
  }

  #section3 .sec-list .sec-item {
    padding-top: 45px;
    display: flex;
    justify-content: center;
  }

  #section3 .sec-list .sec-item .box {
    width: 262px;
    height: 300px;
  }

  #section4 .play-elonxcat {
    height: 300px;
    width: 510px;
    max-width: 100%;
    min-height: unset;
    margin: 0 auto;
  }

  #section4 .play-elonxcat .parent-wrap {
    width: 100%;
  }

  #section4 .play-elonxcat .game-info {
    width: 330px;
    /* max-width: 80%; */
    top: unset;
    right: unset;
    left: 50%;
    transform: translateX(-50%);
    top: calc(100% - 40px);
    height: auto;
    padding: 20px 20px 100px;
  }

  #section4 .play-elonxcat .left-back {
    width: 100%;
  }

  #section5 {
    padding-top: 0 !important;
    margin-top: 300px;
  }

  #section5 .left-back-image {
    display: none;
  }

  #section5 .sec-wrap {
    flex-direction: column;
    align-items: center;
  }

  #section5 .sec-wrap > .right-area {
    position: static;
    transform: translate(0);
  }

  #section6 {
    padding: 30px 0 !important;
    background-position: -150px -200px;
  }

  #section6:after {
    display: none;
  }

  #section6 .sec-item .opacity-box {
    padding: 8px 0;
  }

  #section6 .sec-item .discover-btn button {
    height: 40px;
  }

  #section6 .sec-item {
    padding: 0 0 30px 0 !important;
    display: flex;
    justify-content: center;
  }

  #section6 .sec-item .box {
    width: 280px;
    max-width: 100%;
  }

  #section6 .sec-list {
    gap: 40px 0;
    /* flex-direction: row; */
  }

  .sec7-back-image {
    transform: translateY(100px);
  }

  #section7 {
    margin-top: 30px;
  }

  #section7 .sec-wrap > .left-area {
    display: none;
  }

  #section8 .sec-wrap {
    flex-direction: column;
    align-items: center;
  }

  #section8 .sec-title h2 {
    line-height: 120%;
  }

  #section8 .sec-title .right {
    text-align: left;
  }

  #section8 .sec-wrap > .left-area img {
    width: 350px;
  }

  #section8 .logo-list {
    gap: 8px;
  }

  #section9 .sec-wrap {
    flex-direction: column;
    align-items: center;
    gap: 20px 0;
  }

  #section9 .left-area .chart-area {
    width: 320px;
    height: 320px;
    max-width: 100%;
  }

  #section9 .sec-wrap > .right-area {
    padding: 0 15px;
  }

  #section9 .right-area .top-text h4 {
  }

  #section10 .sub-info {
    gap: 20px 0;
    flex-direction: column;
  }

  #section10 .token-status-row > .status-item {
    flex: 0 1 auto !important;
    width: 350px;
    max-width: 100%;
    min-height: unset;
    gap: 12px 0;
  }

  #section10 .token-status-row {
    flex-direction: column;
    align-items: center;
    gap: 15px 0;
  }

  #section10 .total-supply .total-supply-parent {
    padding: 15px;
    height: auto;
  }

  #section10 .total-supply .left-area {
    width: 100%;
  }

  #section10 .total-supply .right-area {
    display: none;
  }

  #section13 .sec-wrap {
    flex-direction: column;
    align-items: center;
    gap: 20px 0;
  }

  #section13 .sec-wrap > .left-area {
    width: 300px;
    max-width: 100%;
  }

  #section13 .sec-wrap > .right-area {
    padding: 0 15px;
  }

  #section13 .sec-wrap > .right-area .text-parent {
    padding: 20px 16px;
  }

  /* #section14 .slick-track {
    align-items:center;
  } */

  #section14 .sec-wrap {
    gap: 30px;
  }

  /* #section14 .slick-slide {
    display:flex;
    flex-direction:column;
    gap:15px 0;
  } */

  #section14 .sec-wrap .sec-item {
    display: flex !important;
    justify-content: center;
  }

  #section14 .sec-wrap .sec-item .char-image {
    width: 200px;
  }

  #section15 .sec-title {
    margin-bottom: 60px;
  }

  #section15 .left-image {
    display: none;
  }

  #section15 .step-row {
    gap: 60px 0;
    padding: 0 15px;
  }

  #section15 .step-item {
    padding: 20px 24px;
  }

  #section15 .step-item .step-number {
    width: 140px;
    height: 45px;
    top: -30px;
  }

  #section15 .step-item .step-number h4 {
    line-height: 40px;
    font-size: 20px;
  }

  #section15 .right-image {
    display: none;
  }

  .sec16-back {
    transform: translateY(0);
    width: auto;
    height: 5000px;
  }

  .sec16-back img {
    width: auto;
    height: 100%;
  }
  /* 
  .sec16-back {
    width: auto;
    height: 3000px;
  }

  .sec16-back img {
    max-width: unset;
    height: 100%;
  } */

  #section16 {
    padding-top: 30px !important;
  }

  #section16 .sec-wrap:before {
    display: none;
  }

  #section16 .sec-item:before {
    display: none;
  }

  #section16 .sec-wrap {
    /* flex-direction: column; */
    /* align-items: center; */
    /* gap: 20px 0; */
    padding-top: 20px;
  }

  #section16 .sec-wrap .sec-item {
    display: flex;
    justify-content: center;
  }

  #section16 .sec-wrap .sec-item .box {
    width: 230px;
    height: 435px;
    flex: 0 1 auto;
    width: 262px;
    height: 510px;
    max-width: 100%;
  }

  #section17 .question-wrap {
    padding: 40px 20px;
  }

  #section18 .social-list > .social-item {
    width: 140px;
    height: 120px;
  }
  .brand-parent .brand-list .brand-item {
    width: calc(50% - 20px);
    height: 80px;
  }
  #section9 .right-area table tr td:nth-child(1):before {
    width: 12px;
    height: 12px;
    border-radius: 10px;
    top: 14px;
    left: 15px;
  }
  #section9 .right-area .top-text h4 {
    font-size: 2.7rem;
  }
  #section9 .right-area .top-text p {
    font-size: 1.8rem;
  }
  #section9 .right-area table tr th,
  #section9 .right-area table tr td {
    font-size: 1.2rem;
  }
}

/**************************************************/
.div_pop_wrap {
  position: absolute;
  z-index: 999999 !important;
  overflow: hidden;
  background: #000;
  display: none;
  border: 3px solid #000;
  box-sizing: border-box;
}
.div_pop_wrap img {
  display: block;
  border: 0;
}
.div_24box {
  background: #000;
  color: #fff;
  padding: 7px;
  position: relative;
}
.div_24box span {
  color: #fff;
}
.div_24box input {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin-right: 10px;
}
.div_24box span {
  vertical-align: middle;
  font-size: 14px;
}
.div_24box .closewin {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 8px;
  font-weight: bold;
}
@media (max-width: 500px) {
  .div_pop_wrap {
    width: 90% !important;
    left: 5% !important;
    top: 0 !important;
  }
}
