.carousel {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.carousel__viewport {
  overflow: hidden;
  width: 90%;
  margin: 0 auto;
}

.carousel__container {
  display: flex;
  user-select: none;
  transition: transform 0.3s ease;
}

.carousel__slide {
  position: relative;
  flex: 0 0 100%; /* Default to 1 slide per view */
  margin-right: 16px; /* Adjust gap between slides */
}

.carousel__button {
  background: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  font-size: 24px;
  padding: 8px 16px;
  cursor: pointer;
  z-index: 10;
}

.carousel__button--prev {
  position: absolute;
  left: 0;
}

.carousel__button--next {
  position: absolute;
  right: 0;
}

/* Dots styling */
.carousel__dots {
    display: flex;
    gap: 8px;
    margin-top: 16px;
}

.carousel__dot {
width: 10px;
height: 10px;
border-radius: 50%;
background: gray;
border: none;
cursor: pointer;
}

/* Show 4 slides when the screen width is above 1400px */
@media (min-width: 1400px) {
  .carousel__slide {
    flex: 0 0 25%; /* 25% width for 4 slides per view */
  }
}
