.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.youtube-slider .slick-arrow {
  font-size: 24px; /* Adjust size as needed */
  color: #fff; /* Change color */
  background: rgba(0, 0, 0, 0.5); /* Background color */
  border-radius: 50%; /* Round arrows */
  width: 40px; /* Width of the arrow */
  height: 40px; /* Height of the arrow */
  display: flex;
  align-items: center;
  justify-content: center;
}

.youtube-slider .slick-arrow:hover {
  background: rgba(0, 0, 0, 0.8); /* Darker on hover */
}

.youtube-slider .slick-prev {
  left: -50px; /* Position left arrow */
}

.youtube-slider .slick-next {
  right: -50px; /* Position right arrow */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
